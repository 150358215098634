<template>
    <div>
        <!-- Alert: No item found -->
        <b-alert
            variant="danger"
            :show="(role === undefined || role.length === 0) && !isLoading"
        >
            <h4 class="alert-heading">
                {{
                    $t("common.crud.errors.fetch", {
                        resource: $tc("modules.roles.description", 1),
                    })
                }}
            </h4>
            <div class="alert-body">
                {{
                    $t("common.crud.errors.notfound", {
                        resource: $tc("modules.roles.description", 1),
                    })
                }}
                <b-link class="alert-link" :to="{ name: 'roles' }">
                    {{
                        $t("common.crud.errors.list", {
                            resource: $tc("modules.roles.description", 1),
                        })
                    }}
                </b-link>
                {{
                    $t("common.crud.errors.resource", {
                        resource: $tc("modules.roles.description", 2),
                    })
                }}
            </div>
        </b-alert>

        <div v-if="isLoading" class="text-center">
            <b-spinner variant="primary" class="" />
        </div>

        <div v-else>
            <formulateForm
                v-model="formData"
                name="userForm"
                @submit="handleSubmit"
            >
                <b-card noBody class="border mt-1">
                    <b-card-header class="p-1">
                        <b-card-title class="font-medium-2">
                            <span class="align-middle ml-50">
                                {{
                                    $t("common.crud.updateResource", {
                                        resource: $tc(
                                            "modules.roles.description",
                                            1
                                        ).toLowerCase(),
                                    })
                                }}
                            </span>
                        </b-card-title>
                    </b-card-header>

                    <b-col cols="6" class="mt-3 mb-2">
                        <formulateInput
                            :label="$t('common.crud.name')"
                            name="name"
                            :placeholder="$t('common.crud.name')"
                            type="text"
                            validation="optional|max:200,length"
                        />
                    </b-col>

                    <b-col cols="6" class="mb-2">
                        <b-form-group
                            :label="$tc('modules.roles.permissions', 2)"
                        >
                            <v-select
                                id="permissions"
                                ref="multi-select"
                                v-model="formData.permissions"
                                :filterable="true"
                                label="name"
                                :options="permissions"
                                :reduce="(permission) => permission.id"
                                :placeholder="
                                    $t('common.selectOneOrMany', {
                                        resource: $tc(
                                            'modules.roles.permissions',
                                            2
                                        ),
                                    })
                                "
                                required
                                multiple
                                :closeOnSelect="false"
                            >
                                <template slot="no-options">
                                    {{ $t("common.crud.optionsNotFound") }}
                                </template>
                            </v-select>
                        </b-form-group>
                    </b-col>
                </b-card>

                <b-row class="float-right mr-1">
                    <formulateInput
                        :disabled="isLoading"
                        :label="
                            isLoading
                                ? $t('common.crud.updating')
                                : $t('common.crud.update')
                        "
                        inputClass="btn btn-primary"
                        type="submit"
                    />
                </b-row>
            </formulateForm>
        </div>
    </div>
</template>

<script>
import {
    BAlert,
    BCard,
    BLink,
    BRow,
    BSpinner,
    BCardHeader,
    BCardTitle,
    BCol,
    BFormGroup,
} from "bootstrap-vue"
import Api from "@/services/api"
import vSelect from "vue-select"
import _ from "lodash"
import toastMessages from "@/views/components/utilis-spa/utilities/ToastMessages"

export default {
    components: {
        BAlert,
        BLink,
        BSpinner,
        BRow,
        BCard,
        BCardHeader,
        BCardTitle,
        vSelect,
        BFormGroup,
        BCol,
    },
    data() {
        return {
            formData: {
                permissions: [],
                name: "",
            },
            role: _.get(this.$route.params, "role") || [],
            isLoading: true,
            permissions: [],
            relatedPermissions: [],
            permissionsResources: [],
            permissionsFunctionalities: [],
            permissionsData: [],
        }
    },

    mounted() {
        this.getRoleData()
        this.getRolePermissions()
        this.getAllPermissions()
        this.formData.name = this.role.name
    },

    methods: {
        async getRoleData() {
            if (!_.get(this.$route.params, "role")) {
                const roleResponse = await Api.find(
                    "roles",
                    this.$route.params.id,
                    {
                        fields: {
                            roles: "name",
                        },
                    }
                )

                this.role = roleResponse.data
                this.formData.name = this.role.name
            }
        },

        async getRolePermissions() {
            const roleResponse = await Api.one("roles", this.$route.params.id)
                .all("permissions")
                .get()

            this.relatedPermissions = roleResponse.data
            const permissionsIds = this.relatedPermissions.map(el => el.id)
            this.formData.permissions = permissionsIds
        },

        async getAllPermissions() {
            const baseUrl = Api.apiUrl

            try {
                const response = await Api.request(
                    `${baseUrl}/roles/-actions/get-all-permissions`,
                    "GET"
                )

                this.permissions = response.data
            } finally {
                this.isLoading = false
            }
        },

        async handleSubmit() {
            const permissionsRequest = []
            this.formData.permissions.forEach(permission => {
                permissionsRequest.push({
                    type: "permissions",
                    id: permission,
                })
            })

            try {
                await Api.update("roles", {
                    id: this.$route.params.id,
                    name: this.formData.name,
                    permissions: permissionsRequest,
                    include: "permissions",
                })

                this.$toast(toastMessages.getSuccessMessage())
            } catch (e) {
                this.$toast(toastMessages.getUnexpectedError())
            }
        },
    },
}
</script>
